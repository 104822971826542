
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("discourse-widget-hbs/helpers", function(){ return i("discourse-widget-hbs/helpers");});
d("ember-this-fallback/deprecations-helper", function(){ return i("ember-this-fallback/deprecations-helper");});
d("ember-this-fallback/get-owner", function(){ return i("ember-this-fallback/get-owner");});
d("ember-this-fallback/is-component", function(){ return i("ember-this-fallback/is-component");});
d("ember-this-fallback/this-fallback-helper", function(){ return i("ember-this-fallback/this-fallback-helper");});
d("ember-this-fallback/try-lookup-helper", function(){ return i("ember-this-fallback/try-lookup-helper");});
d("ember-this-fallback/types/deprecations", function(){ return i("ember-this-fallback/types/deprecations");});
d("ember-this-fallback/types/util", function(){ return i("ember-this-fallback/types/util");});
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "discourse-widget-hbs/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-this-fallback/-embroider-implicit-modules.js";
